import React from 'react';
import Card from 'react-bootstrap/Card';

const CarouselComponent = ({ text, imagePath }) => {
  return (
    <Card className="shadow-lg p-3 rounded mb-5">
      <Card.Img variant="top" src={imagePath} style={{ width: '100%', height: 'auto' }}/>
      <Card.Body>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CarouselComponent;
