import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './Components/CarouselComponent'; // Assuming ExampleCarouselImage is in the same directory
import Footer from './Components/Footer'; // Adjust the import path as necessary



export const Home = () => {
  const scrollDown = () => {
    window.scrollBy({
      top: 1100, 
      behavior: 'smooth',
    });
  };

  return (
    <React.Fragment>
      <div id="topSection">
        <div id="memo">
          <p id="slogan">Design . Fabricate . Install</p>
          <p><b>Bespoke wood work, crafted with care.</b></p>
        </div>

        <div className="arrow bounce" onClick={scrollDown}>
          <a className="fa fa-arrow-down fa-2x" href="#"></a>
        </div>

        <div id="testimonials">
          <p>Testimonials</p>
          <p><i>"Test Test Test Test"</i></p>
         
         
          
        </div>
      </div>
      <Container id="whatWeDoSection">
        <Row>
          <Col md={8} sm={12}>
            <h2>What We Do</h2>
            <p>
              We specialize in bespoke woodwork, creating unique pieces that are crafted with care and precision. 
              Our services include design, fabrication, and installation, ensuring that every project meets your exact specifications.
              Whether you're looking for custom furniture, cabinetry, or other woodwork, we are dedicated to bringing your vision to life.
            </p>
          </Col>
          <Col md={4} sm={12}>
          <Carousel fade interval={3000} className="small-carousel">
              <Carousel.Item>
                <CarouselImage text="" imagePath={require('./Images/handles.jpg')} />
              </Carousel.Item>
              <Carousel.Item>
                <CarouselImage text="" imagePath={require('./Images/wardrobe-shut2.jpg')} />
              </Carousel.Item>
              <Carousel.Item>
                <CarouselImage text="" imagePath={require('./Images/wardrobe-open2.jpg')} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <div id="AboutUsSection">
        <h2>My Story: Kyle CEO or.... About Us????? </h2>
        <p>
          "I founded KDS Bespoke in 2018.... or..../ KDS bespoke was founded in 2018, and has been a one man band the for the majority of its exitence.
           Originally named KDS Joinery, A name the company outgrew as projects became more custom and personal to each client.
           blah blah blah blah blah blah blah blah blah blah blah blah blah blah  
        </p>


      </div>

      <div id="bottomSection">
      
        <Container id="pictureContainer" fluid>
          {
            <Carousel fade interval={3000} >
            <Carousel.Item>
              <CarouselImage text="" imagePath={require('./Images/handles.jpg')} />
            </Carousel.Item>

            <Carousel.Item>
              <CarouselImage text="" imagePath={require('./Images/wardrobe-shut2.jpg')}/>
            </Carousel.Item>
            <Carousel.Item>
              <CarouselImage text="" imagePath={require('./Images/wardrobe-open2.jpg')}/>
            </Carousel.Item>
          </Carousel>
          
          /* <Row>
            <Col xl={4}>
              <Card id="greetingcardMain" className="shadow-lg p-3 rounded mb-5">npm buil
                <Card.Img variant="top" src={require('./Images/large_rack.jpg')} />
              </Card>
            </Col>
            <Col xl={4}>
              <Card id="greetingcardMain" className="shadow-lg p-3 rounded mb-5">
                <Card.Img variant="top" src={require('./Images/radiator_cover.jpg')} />
              </Card>
            </Col>
            <Col xl={4}>
              <Card id="greetingcardMain" className="shadow-lg p-3 rounded mb-5">
                <Card.Img variant="top" src={require('./Images/table.jpg')} />
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};
